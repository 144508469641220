<template>
    <div>
        <span v-if="row.item.status === 'Open'">
            <b-badge variant="success">{{ row.item.status }}</b-badge>
        </span>
        <span v-if="row.item.status === 'Approved'">
            <b-badge variant="primary">{{ row.item.status }}</b-badge>
        </span>
        <span v-if="row.item.status === 'Rejected'">
            <b-badge variant="danger">{{ row.item.status }}</b-badge>
        </span>
    </div>
</template>

<script>
export default {
    name: 'transfer-request-row-status',
    props: {
        row: {
            type: Object,
            required: true
        }
    }
};
</script>

<style>

</style>
